import request, { ResponsePageableData } from '@/libs/request'

/** 酒店评分维度列表 */
export const getHotelScoreDimensionList = (data?) => request<ResponsePageableData>({ url: '/adminapi/HotelScoreDimension/getHotelScoreDimensionList', data })

/** 酒店评分维度新增/编辑 */
export const editHotelScoreDimension = (data?) => request<ResponsePageableData>({ url: '/adminapi/HotelScoreDimension/editHotelScoreDimension', data })

/** 酒店评分维度删除 */
export const delHotelScoreDimension = (data?) => request<ResponsePageableData>({ url: '/adminapi/HotelScoreDimension/delHotelScoreDimension', data })
