

























































































































































































import Vue from 'vue'
import { setModelField, getOssUrl } from '@/api/common'
import AmapPicker, { getDefaultLngLat } from '@/components/amap/amap-picker.vue'
import SelectHotelLabel from '@/components/select/select-hotel-label.vue'
import SelectHotelFacility from '@/components/select/select-hotel-facility.vue'
import SelectHotelPolicy from '@/components/select/select-hotel-policy.vue'
import SelectHotelFeature from '@/components/select/select-hotel-feature.vue'
import SelectHotelScoreDimension from '@/components/select/select-hotel-score-dimension.vue'
import SelectHotelEvaluationLabel from '@/components/select/select-hotel-evaluation-label.vue'
import SelectHouseRequireRead from '@/components/select/select-house-require-read.vue'
import ModelFieldEditor from '@/components/form/model-field-editor.vue'
import SelectGroupCompany from '@/components/select/select-group-company.vue'
import UploadImage from '@/components/upload/upload-image.vue'
import UploadImageTwoone from '@/components/upload/upload-imageTwoOne.vue'
import { useTable } from '@/components/table/table-hook'
import { getHotelList, editHotel, delHotel, createHotelRow } from '@/api/hotel'
import { getVipLevel } from '@/api/config'
import { pullHotelInfo } from '@/api/green-cloud'
import { pullPMSHotelInfo } from '@/api/pms_cloud'
import { regionData } from 'element-china-area-data'
import { getTreeName } from '@/libs/vue-tools/index'
import { export_json_to_excel } from '@/vendor/Export2Excel'
import { cloneDeep, forEach } from 'lodash'
import { getAdminInfo } from '@/api/admin'
import SelectHotel from '@/components/select/select-hotel.vue' //酒店选择器
export default Vue.extend({
	components: {
		SelectHotelEvaluationLabel,
		SelectHotelLabel,
		SelectHotelFacility,
		UploadImage,
		UploadImageTwoone,
		SelectHotelPolicy,
		SelectHotelFeature,
		SelectHotelScoreDimension,
		SelectHouseRequireRead,
		SelectGroupCompany,
		AmapPicker,
		ModelFieldEditor,
		SelectHotel,
	},

	data() {
		return {
			indexs: 0,
			baseUrl: '',
			oldBaseUrl: '',
			endTime: '10:00',
			startTime: '07:00',
			disabled_switch: false,
			keyne: 0 as any,
			hotelList: [] as any,
			adminInfo: {},
			vipLevel: [] as any,
			editHotel,
			regionData,
			mapDialogShow: false,
			hotelStateConfig: JSON.parse(localStorage.getItem('desc') || ''),
			map_data: getDefaultLngLat(),
			...useTable({
				name: '酒店',
				formdata: createHotelRow(),
				query: {
					/** 筛选城市ID */
					city_id: 0,
					sort_key: 'sort',
					sort_type: 'desc',
				},
				getData: (query) =>
					getHotelList(query).then((res) => {
						let arr = res
						res.data.rows.forEach((it) => {
							it.breakfast_business_hours = it.breakfast_business_hours.split(',')
						})
						// 这里演示对拿到的数据追加一些自定义属性
						res.data.rows.forEach((it) => {
							it._custom_name = '自定义的属性'
							it.edit_image = it.cover_image
							it.edit_image1 = it.breakfast_img
							if (it.cover_image != null) {
								it.cover_image = it.cover_image.split(',')[0]
							}
							if (it.breakfast_img != null) {
								it.breakfast_img = it.breakfast_img.split(',')[0]
							}
							it.original_price = it.original_price / 100
							it.price = it.price / 100
							it.breakfast_price = it.breakfast_price / 100
							it.breakfast_discount_rate = it.breakfast_discount_rate * 10
						})
						return res.data
					}),
				delData: (row) => delHotel({ id: row.id }),
			}),
		}
	},
	inject: ['oss56'],

	methods: {
		SelectHotelLabelinput(e) {
			this.table_formdata.label_ids = e
		},
		getOssUrl() {
			getOssUrl().then((res) => {
				this.baseUrl = res.data.domain
				this.oldBaseUrl = res.data.old_domain
			})
		},
		changeStartTime() {},
		hotelchange() {
			this.tableGetData()
		},
		change_micro() {
			if ((this.table_formdata as any).api_type) {
				this.disabled_switch = true
			} else {
				this.disabled_switch = false
			}
		},
		checkNumberFloat(value) {
			// 去掉数字和.以外的内容
			value = value.replace(/[^\d.]/g, '')
			// 不允许.开头
			value = value.replace(/^\./g, '')
			// 去掉连续的.
			value = value.replace(/\.{2,}/g, '.')
			// 只允许一个点，第一个会匹配到第一个点，第二个会匹配到剩余的点
			// 因为是输入框，最多会呈现到‘1.23.’这种方式
			// 注意第一个点是字符串方式匹配，第二个是正则匹配
			value = value.replace('.', '$#$').replace(/\./g, '').replace('$#$', '.')
			//两位小数，‘\.(\d\d)’匹配到两位数
			value = value.replace(/^(-)*(\d+)\.(\d).*$/, '$1$2.$3')
			if (value > 10) {
				value = 10
			}
			if (value < 0) {
				value = 0
			}
			return value
		},

		close() {
			this.keyne++
		},
		changRestaurant(e) {},
		edit(row) {
			const params = cloneDeep(row)
			if (params.cover_image != null) {
				params.cover_image = row.edit_image.split(',')
			}
			if (params.breakfast_img != null) {
				params.breakfast_img = row.edit_image1.split(',')
			} else {
				params.breakfast_img = []
			}
			// if (params.vip_config != '') {
			// 	params.vip_config = row.vip_config.split(',')
			// }
			params.is_catering = params.is_catering == 0 ? false : true
			params.api_type = params.api_type == 1 ? false : true
			;(this.$refs.formDialogRef as any).open(params)
			this.indexs++
			this.startTime = params.breakfast_business_hours[0]
			this.endTime = params.breakfast_business_hours[1]
			this.change_micro()
		},
		formatJson(filterVal, jsonData) {
			return jsonData.map((v) => filterVal.map((j) => v[j]))
		},
		inportexcel() {
			// 兼容ie10哦！
			require.ensure([], () => {
				const tHeader = ['序号', '封面图', '地址', '原价', '价格'] //将对应的属性名转换成中文
				// const tHeader = [];

				const filterVal = ['id', 'cover_image', 'address', 'original_price', 'price'] //table表格中对应的属性名
				const list = this.hotelList
				const data = this.formatJson(filterVal, list)
				export_json_to_excel(tHeader, data, '酒店列表')
			})
		},
		getTreeName,
		synchronous() {
			pullHotelInfo().then(() => this.$message.success('保存成功') && this.tableGetData())
		},
		pullPMSHotel() {
			pullPMSHotelInfo().then(() => this.$message.success('保存成功') && this.tableGetData())
		},
		onSubmit(row) {
			console.log(row)

			let param = row
			let base_url_length = this.baseUrl.split('').length
			if (param.cover_image != null) {
				let coverImage = [] as any
				param.cover_image.forEach((el) => {
					if (el.indexOf(this.baseUrl) !== -1) {
						el = el.slice(base_url_length)
						if (el.indexOf(this.baseUrl) !== -1) {
							el = el.slice(base_url_length)
						}
					}
					coverImage.push(el)
				})
				param.cover_image = coverImage.toString() // 多图
			}
			if (param.breakfast_img != null) {
				let breakfastImage = [] as any
				param.breakfast_img.forEach((el) => {
					if (el.indexOf(this.baseUrl) !== -1) {
						el = el.slice(base_url_length)
						if (el.indexOf(this.baseUrl) !== -1) {
							el = el.slice(base_url_length)
						}
					}
					breakfastImage.push(el)
				})
				param.breakfast_img = breakfastImage.toString() // 多图
			}
			param.label_ids = JSON.stringify(param.label_ids) // 标签
			param.facility_ids = JSON.stringify(param.facility_ids) // 设施
			let area_chinese: any[] = []
			param.area_adcode &&
				param.area_adcode.forEach((item) => {
					const name = getTreeName(regionData, item)
					name && area_chinese.push(name)
				})
			param.area_chinese = JSON.stringify(area_chinese) // 省市区中文名
			param.area_adcode = JSON.stringify(param.area_adcode) // 省市区
			param.policy_ids = JSON.stringify(param.policy_ids) // 政策
			param.feature_ids = JSON.stringify(param.feature_ids) // 酒店特色
			param.breakfast_price = param.breakfast_price * 100 //早餐单价
			param.breakfast_discount_rate = param.breakfast_discount_rate / 10 // 新用户早餐折扣
			param.score_dimension_ids = JSON.stringify(param.score_dimension_ids) // 评分维度
			param.evaluation_label_ids = JSON.stringify(param.evaluation_label_ids) // 评价标签
			param.require_read_ids = JSON.stringify(param.require_read_ids) // 订房必读
			// if (param.vip_config != null) {
			// 	param.vip_config = param.vip_config.toString() // 会员卡类型
			// }
			param.original_price = param.original_price * 100
			param.price = param.price * 100
			param.is_catering = param.is_catering == true ? 1 : 0
			param.api_type = param.api_type == true ? 2 : 1
			param.breakfast_business_hours[0] = this.startTime
			param.breakfast_business_hours[1] = this.endTime
			return editHotel(param).then(() => this.$message.success('保存成功') && this.tableGetData())
		},
		onPointChange() {
			;(this.table_formdata as any).longitude = this.map_data.lng
			;(this.table_formdata as any).latitude = this.map_data.lat
		},
		changeTj(row) {
			setModelField({
				model: 'Hotel',
				id: row.id,
				field: 'is_recommend',
				value: row.is_recommend == 1 ? 0 : 1,
			}).then((res) => {
				this.$message.success('操作成功！')
				if (row.is_recommend == 1) {
					row.is_recommend = 0
				} else {
					row.is_recommend = 1
				}
			})
		},
	},
	created() {
		this.getOssUrl()
		// console.log(localStorage.getItem("desc"),111111);
		// var desc = localStorage.getItem("desc") || ''
		// this.hotelStateConfig = JSON.parse(desc)
		getHotelList().then((res) => {
			// 这里演示对拿到的数据追加一些自定义属性
			this.hotelList = res.data.rows
			this.hotelList.forEach((it) => {
				// it._custom_name = '自定义的属性'
				if (it.cover_image != null) {
					it.cover_image = it.cover_image.split(',')[0]
				}
				if (it.breakfast_img != null) {
					it.breakfast_img = it.breakfast_img.split(',')[0]
				}
				it.original_price = it.original_price / 100
				it.price = it.price / 100
				it.breakfast_price = it.breakfast_price / 100
				it.breakfast_discount_rate = it.breakfast_discount_rate * 10
			})
			return this.hotelList
		}),
			// console.log(this)
			this.tableGetData().then(() => {
				// 访问自定义属性不会让ts报错，只是无法识别类型
				// console.log('访问数据的自定义属性', this.table_data[0]?._custom_name)
				// console.log('访问普通属性, TS是有提示的，包括属性的注释', this.table_data[0]?.name)
			})
		getAdminInfo().then((res) => {
			this.adminInfo = res.data
		})
		getVipLevel().then((res) => {
			let vipLevel = res.data as any
			vipLevel.forEach((el) => {
				if (el.can_buy == 1) {
					this.vipLevel.push(el)
				}
			})
		})
	},
})
