












import Vue from 'vue'
import SelectMixin from './select-mixin'
import { getHotelLabelList } from '@/api/hotel-label'
import draggable from 'vuedraggable'
import { forEach } from 'lodash'
import { set } from 'vue/types/umd'

/**
 * 酒店标签选择器
 * 所有属性都可以从外部直接绑定到<el-select>上
 * 使用SelectMixin只需要在data中设置一个api属性，本项目标准列表数据接口返回的格式，都支持这样操作
 *
 * @event input(val) 数据变化，包括切换、选择、清空都会触发，启用多选模式后会是一个数组
 * @event load(rows,total) 加载数据完成，rows数据列，total总条数
 */

export default Vue.extend({
	mixins: [SelectMixin],
	props: {
		placeholder: {
			type: String,
			default: '请选择标签',
		},
	},
	components: {
		draggable,
	},
	data() {
		return {
			api: getHotelLabelList,
			taglist: [] as any[],
			value: [] as any[],
			rows: [] as any[],
		}
	},
	mounted() {
		this.taglist = []
		setTimeout(() => {
			forEach(this.value, (item) => {
				forEach(this.rows, (row) => {
					if (row.id == item) {
						this.taglist.push(row)
					}
				})
			})
		}, 500)
		// 使用ts时，如果用了混入，本组件中无法从this上获得混入的属性，暂不清楚解决办法，下一行打开就会报错
		// 如果需要使用，可以先在<script>上关闭ts
		// console.log(this.rows);
	},
	methods: {
		change() {
			if (this.value.length > 5) {
				this.$message.warning('最多只能选择五个标签')
				this.value.splice(-1)
			} else {
				this.taglist = []
				forEach(this.value, (item) => {
					forEach(this.rows, (row) => {
						if (row.id == item) {
							if (this.taglist.length < 5) {
								this.taglist.push(row)
							}
						}
					})
				})
				this.$emit('input', this.value)
			}
		},
		onDragEnd() {
			this.value = []
			forEach(this.taglist, (item, index) => {
				this.value[index] = item.id
			})
		},
		removeTag(index) {
			console.log(111)
			this.taglist.splice(index, 1)
			this.value.splice(index, 1)
		},
	},
})
