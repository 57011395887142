





































import Vue from 'vue'
import { useTable } from '@/components/table/table-hook'
import { getHotelEvaluationLabelList, editHotelEvaluationLabel, delHotelEvaluationLabel } from '@/api/hotel-evaluation-label'

export default Vue.extend({
	data() {
		return {
			editHotelEvaluationLabel,
			...useTable({
				name: '酒店标签',
				formdata: {
					/** ID */
					id: 0,
					/** 名称 */
					name: '',
				},
				getData: (query) => getHotelEvaluationLabelList(query).then((res) => res.data),
				delData: (row) => delHotelEvaluationLabel({ id: row.id }),
			}),
		}
	},
	created() {
		this.tableGetData()
	},
})
