





































import Vue from 'vue'
import { useTable } from '@/components/table/table-hook'
import { getGroupCompanyList, editGroupCompany, delGroupCompany } from '@/api/hotel-group-company'
export default Vue.extend({
	data() {
		return {
			editGroupCompany,
			...useTable({
				name: '集团公司',
				formdata: {
					/** ID */
					id: 0,
					/** 名称 */
					name: '',
				},
				getData: (query) => getGroupCompanyList(query).then((res) => res.data),
				delData: (row) => delGroupCompany({ id: row.id }),
			}),
		}
	},
	created() {
		this.tableGetData()
	},
})
