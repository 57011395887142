import request, { ResponsePageableData } from '@/libs/request'

/** 订房必读列表 */
export const getRoomRequireReadList = (data?) => request<ResponsePageableData>({ url: '/adminapi/RequireRead/getRequireReadList', data })

/** 订房必读新增/编辑 */
export const editRoomRequireRead = (data?) => request<ResponsePageableData>({ url: '/adminapi/RequireRead/editRequireRead', data })

/** 订房必读删除 */
export const delRoomRequireRead = (data?) => request<ResponsePageableData>({ url: '/adminapi/RequireRead/delRequireRead', data })
