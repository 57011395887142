








































import Vue from 'vue'
import { getOssUrl } from '@/api/common'
import { useTable } from '@/components/table/table-hook'
import { getHotelFeatureList, editHotelFeature, delHotelFeature } from '@/api/hotel-feature'
export default Vue.extend({
	data() {
		return {
			baseUrl: '',
			oldBaseUrl: '',
			editHotelFeature,
			...useTable({
				name: '酒店特色',
				formdata: {
					/** ID */
					id: 0,
					/** 名称 */
					name: '',
					//图标
					icon: undefined,
				},
				getData: (query) => getHotelFeatureList(query).then((res) => res.data),
				delData: (row) => delHotelFeature({ id: row.id }),
			}),
		}
	},
	inject: ['oss56'],
	methods: {
		getOssUrl() {
			getOssUrl().then((res) => {
				this.baseUrl = res.data.domain
				this.oldBaseUrl = res.data.old_domain
			})
		},
		onSubmit(row) {
			let base_url_length = this.baseUrl.split('').length
			if (row.icon.indexOf(this.baseUrl) !== -1) {
				row.icon = row.icon.slice(base_url_length)
			}
			return editHotelFeature(row).then(() => this.$message.success('保存成功') && this.tableGetData())
		},
	},
	created() {
		this.getOssUrl()
		this.tableGetData()
	},
})
